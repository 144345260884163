import React from 'react'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import styles from './signup-modal.module.scss'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import { sendSubscribeRequest } from '../../api/mailing_list'
import { Link } from 'gatsby'

// TODO refactor to use redux

class SignUpModal extends React.Component {
  constructor(props) {
    super(props)

    // Function binding
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
    this.handleSuccessClose = this.handleSuccessClose.bind(this)
    this.handleFormClose = this.handleFormClose.bind(this)

    // Component internal state
    this.state = {
      submitting: false, // When true, spinner displayed
      submitted: false, // Tracks if the form has been submitted before
      emailField: {
        value: '',
        valid: false
      }
    }
  }


  // handleFormClose is called when the user closes the form dialog
  handleFormClose() {
    this.props.onClose()
  }

  // handleSuccessClose is called when the user closes the success dialog
  handleSuccessClose() {
    // Reset the form
    this.setState({
      submitting: false,
      submitted: false,
      submissionError: false,
      emailField: {
        value: '',
        valid: true
      }
    })

    // Let the parent property know we are done
    this.props.onClose()
  }

  // handleErrorClose is called when the user closes the error dialog
  handleErrorClose() {
    // Reset the form (except for the email values)
    this.setState({
      submitting: false,
      submitted: false,
      submissionError: false
    })

    // Let the parent property know we are done
    this.props.onClose()
  }

  // handleFieldChange updates the state when a field is updated
  handleFieldChange(e) {
    switch (e.target.id) {
      case 'email':
        this.setState({
          submitted: this.state.emailField.submitted,
          emailField: {
            value: e.target.value,
            valid: this.validateEmail(e.target.value)
          }
        })
        break
      default:
        break
    }
  }


  // handleKeyPress is used to submit the form when "Enter" is pressed
  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSubmit()
      e.preventDefault() // Do not actually submit the form directly
    }
  }


  // handleSubmit validates the form fields and, if all is ok, submits the data
  handleSubmit() {
    // Mark the form as submitted (and invalid) and then return
    if (!this.state.emailField.valid) {
      this.setState({
        submitted: true,
        emailField: { value: this.state.emailField.value, valid: false }
      })
      return
    }

    // Everything seems good, so submit the form
    this.setState({ submitting: true })
    sendSubscribeRequest(this.state.emailField.value)
      .then(
        (result) => {
          this.setState({
            submitting: false,
            submitted: true,
            submissionError: false
          })
        },
        (error) => {
          this.setState({
            submitting: false,
            submitted: true,
            submissionError: true
          })
        })
  }

  // validateEmail checks that the provided email matches a basic email regex
  validateEmail(email) {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    return emailRegex.test(String(email).toLowerCase())
  }

  render() {
    return (
      this.props.open && <div>
        <Dialog open={!this.state.submitted && !this.state.submitting} onClose={this.handleFormClose}
                aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter your email address here to subscribe to the Redox Security mailing list.
            </DialogContentText>
            <TextField
              margin="dense"
              id="email"
              label="Your Email Address"
              type="email"
              variant="outlined"
              fullWidth
              onChange={this.handleFieldChange} // Manage update
              onKeyPress={this.handleKeyPress} // Manage submission
              value={this.state.emailField.value}
              error={this.state.submitted && !this.state.emailField.valid}
              helperText={
                (this.state.submitted && !this.state.emailField.valid) ? 'Invalid email address' : ''
              }
            />
            <div className={styles.small}>
              By submitting this form, I agree to receive marketing communications from Redox Security, LLC,
              which I can unsubscribe from at any time. Form data is managed in accordance with our <Link
              to="/privacy_policy">Privacy Policy</Link>.
            </div>
          </DialogContent>
          <DialogActions>
            {this.state.submitting && <CircularProgress color="secondary" size={24}/>}
            <Button onClick={this.handleFormClose} color="primary">
              Cancel
            </Button>
            <Button
              disabled={this.state.submitted && !this.state.emailField.valid}
              onClick={this.handleSubmit}
              color="primary">
              Subscribe
            </Button>
          </DialogActions>
        </Dialog>

        <Backdrop open={this.state.submitting}>
          <CircularProgress color="inherit"/>
        </Backdrop>

        <Dialog open={this.state.submitted && !this.state.submitting && !this.state.submissionError}
                onClose={this.handleSuccessClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Thank you!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              We just sent an email to verify that you are actually you.
              To complete the subscription, please verify your address.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.state.submitting && <CircularProgress color="secondary" size={24}/>}
            <Button onClick={this.handleSuccessClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.submitted && !this.state.submitting && this.state.submissionError}
                onClose={this.handleSuccessClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Uh oh!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Something didn't go quite right. Please check your internet connection.
              If that doesn't fix it, please try again later.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSuccessClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    )
  }
}

export default SignUpModal
