import React from 'react'
import { connect } from 'react-redux'
import {
  modalContactHide,
  modalContactHideError,
  modalContactHideOK,
  modalContactShowError,
  modalContactShowOK
} from '../../state/dispatchers/modal-contact'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import styles from './styles.module.scss'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import { sendContactRequest } from '../../api/contact-request'
import { Link } from 'gatsby'

class ModalContact extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        firstName: '',
        lastName: '',
        businessEmail: '',
        businessPhone: '',
        companyName: '',
        comments: '',
        sourceID: ''
      }
    }
  }

  // handleChange updates the state when a form field is updated
  handleChange = (name) => ({ target: { value } }) => {
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    })
  }

  handleSubmit = () => {
    const attributes = {
      ...this.state.form,
      sourceID: this.props.lastSourceID
    }
    sendContactRequest(attributes).then(r => {
      // If there are problems, show an error modal and return
      if (r.errors) {
        this.props.modalContactHide()
        this.props.modalContactShowError()
        return
      }

      // If everything is good, show the success modal
      this.props.modalContactHide()
      this.props.modalContactShowOK()

      // Reset the form state
      this.setState({
        form: {
          firstName: '',
          lastName: '',
          businessEmail: '',
          businessPhone: '',
          companyName: '',
          comments: '',
          sourceID: ''
        }
      })
    })
  }

  render() {
    return (
      <>
        <Dialog
          maxWidth={'sm'}
          fullWidth={true}
          open={this.props.formVisible}
          onClose={this.props.modalContactHide}>
          <DialogTitle id="contact-form-dialog-title">Contact Us</DialogTitle>
          <DialogContent className={styles.content}>
            <DialogContentText>
              Schedule a time to discuss ways regular offensive exercises can improve your information-security program.
            </DialogContentText>
            <form className={styles.formWrapper}>
              <div className={styles.inputRowHalf}>
                <TextField
                  label="First Name"
                  type="text"
                  required
                  value={this.state.form.firstName}
                  onChange={this.handleChange('firstName')}
                  className={styles.textInput}
                />
              </div>
              <div className={styles.inputRowHalf}>
                <TextField
                  label="Last Name"
                  type="text"
                  required
                  value={this.state.form.lastName}
                  onChange={this.handleChange('lastName')}
                  className={styles.textInput}
                />
              </div>
              <div className={styles.inputRow}>
                <TextField
                  label="Business Email"
                  type="email"
                  required
                  fullWidth
                  value={this.state.form.email}
                  onChange={this.handleChange('businessEmail')}
                  className={styles.textInput}
                />
              </div>
              <div className={styles.inputRow}>
                <TextField
                  label="Business Phone"
                  type="tel"
                  required
                  fullWidth
                  value={this.state.form.phone}
                  onChange={this.handleChange('businessPhone')}
                  className={styles.textInput}
                />
              </div>
              <div className={styles.inputRow}>
                <TextField
                  label="Company"
                  type="text"
                  required
                  fullWidth
                  value={this.state.form.companyName}
                  onChange={this.handleChange('companyName')}
                  className={styles.textInput}
                />
              </div>
              <div className={styles.inputRow}>
                <TextField
                  label="Comments"
                  type="text"
                  variant="filled"
                  fullWidth
                  rows={3}
                  multiline
                  value={this.state.form.comments}
                  onChange={this.handleChange('comments')}
                  className={styles.textInput}
                />
              </div>
            </form>
          </DialogContent>
          <div className={styles.small}>
            By submitting this form, I agree to be contacted by Redox Security, LLC.<br />
            Form data is managed in accordance with our <Link to="/privacy_policy">Privacy Policy</Link>.
          </div>
          <DialogActions className={styles.actions}>
            <Button color="secondary" onClick={this.props.modalContactHide}>
              Cancel
            </Button>
            <Button color='primary' onClick={this.handleSubmit}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.props.okVisible}
          maxWidth={'sm'}
          fullWidth={true}>
          <DialogTitle id="contact-form-dialog-ok-title">Thank You</DialogTitle>
          <DialogContent className={styles.okContent}>
            <DialogContentText>
              Your request has been submitted.<br/>
              Someone from our team will contact you shortly.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={this.props.modalContactHideOK}>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.props.errorVisible}
          maxWidth={'sm'}
          fullWidth={true}>
          <DialogTitle id="contact-form-dialog-ok-title">Uh oh!</DialogTitle>
          <DialogContent className={styles.okContent}>
            <DialogContentText>
              There was a problem submitting your request.<br/>
              Please email <a href="mailto:'contact@redoxsecurity.com'">contact@redoxsecurity.com</a> or try again soon.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={this.props.modalContactHideError}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

ModalContact.propTypes = {
  formVisible: PropTypes.bool.isRequired,
  okVisible: PropTypes.bool.isRequired,
  errorVisible: PropTypes.bool.isRequired,
  modalContactHide: PropTypes.func,
  modalContactShowError: PropTypes.func,
  modalContactHideError: PropTypes.func,
  modalContactShowOK: PropTypes.func,
  modalContactHideOK: PropTypes.func
}


const mapStateToProps = (state) => ({
  formVisible: state.modalContact.formVisible,
  okVisible: state.modalContact.okVisible,
  errorVisible: state.modalContact.errorVisible,
  lastSourceID: state.modalContact.sourceID
})

const mapDispatchToProps = (dispatch) => ({
  modalContactHide: () => dispatch(modalContactHide()),
  modalContactShowError: () => dispatch(modalContactShowError()),
  modalContactHideError: () => dispatch(modalContactHideError()),
  modalContactShowOK: () => dispatch(modalContactShowOK()),
  modalContactHideOK: () => dispatch(modalContactHideOK())
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalContact)
