const baseURL = process.env.GATSBY_API_URL


export interface ContactRequestAttributes {
  firstName: string,
  lastName: string,
  businessEmail: string,
  businessPhone: string,
  companyName: string,
  comments: string,
  sourceID: string,
}

export interface ContactRequestOkResponse {
  type: string
  id: string
}

export interface ContactRequestErrorResponse {
  errors: Array<ContactRequestErrorResponseData>
}

export interface ContactRequestErrorResponseData {
  status: string
  title: string
  detail?: string
}

export const sendContactRequest = (attributes: ContactRequestAttributes): Promise<ContactRequestOkResponse | ContactRequestErrorResponse> => {
  const url = baseURL + "/v1/request-contact"

  // Format the request
  const request = {
    data: {
      type: "contact_request",
      attributes: attributes
    }
  }
  const requestJson = JSON.stringify(request)

  // Send the request
  return fetch(url, {
    method: "POST",
    mode: "cors",
    body: requestJson
  })
    .then(resp => {
      if (!resp.ok) {
        throw new Error(resp.statusText)
      }
      return resp.json()
    })
    .then(resp => {
      if (resp.type !== "") {
        return <ContactRequestOkResponse>resp
      }
      if (resp.status !== "") {
        return <ContactRequestErrorResponse>resp
      }
    })
}