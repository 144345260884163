import { createMuiTheme } from '@material-ui/core/styles'

let theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#60d45c',
    },
    alt: {
      main: '#6923BF'
    },
    secondary: {
      main: '#d02727',
    },
    background: {
      default: '#07070A',
      paper: '#212122'
    }
  },
  typography: {
    fontFamily: '"Montserrat", "sans-serif"',
    h1: {
      fontFamily: '"Oswald", "sans-serif"'
    },
    h2: {
      fontFamily: '"Oswald", "sans-serif"'
    },
    h3: {
      fontFamily: '"Oswald", "sans-serif"'
    },
    h4: {
      fontFamily: '"Oswald", "sans-serif"'
    },
    h5: {
      fontFamily: '"Oswald", "sans-serif"'
    },
    h6: {
      fontFamily: '"Oswald", "sans-serif"'
    }
  }
})

export default theme