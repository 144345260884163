import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import '../../styles/index.scss'
import '../../styles/global.scss'
import { ThemeProvider } from '@material-ui/core/styles'
import { graphql, useStaticQuery } from 'gatsby'
import theme from './mui-theme'
import { Helmet } from 'react-helmet'
import ModalContact from '../../components/modal-contact'
import styles from './base.module.scss'

const Base = (props) => {
  const data = useStaticQuery(graphql`
      query {
          site {
              siteMetadata {
                  title
              }
          }
      }`)

  const subject = props.subject || 'Iterative Information Security Exercise'

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{subject} | {data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.title}/>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Header/>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            {props.children}
          </div>
          <Footer className={styles.footer}/>
        </div>
        <ModalContact/>
      </ThemeProvider>
    </>
  )
}


export default Base