const baseURL = process.env.GATSBY_API_URL
const mailingListID = process.env.GATSBY_API_MAILING_LIST_ID

export const sendSubscribeRequest = (email) => {
  const url = baseURL + '/v1/mailing_list/subscribe'
  const params =
    '?l=' + mailingListID +
    '&e=' + email

  return fetch(url + params)
    .then(res => res.json())
}

export const sendVerifyRequest = (validation, subscriberID) => {
  const url = baseURL + '/v1/mailing_list/verify'
  const params =
    '?l=' + mailingListID +
    '&v=' + validation +
    '&s=' + subscriberID

  return fetch(url + params)
    .then(r => r.json())
}

export const sendUnsubscribeRequest = (subscriberID, campaignID = null) => {
  const url = baseURL + '/v1/mailing_list/unsubscribe'
  let params =
    '?l=' + mailingListID +
    '&s=' + subscriberID

  // Add the Campaign ID to the request if one was provided
  if (campaignID) {
    params = params + '&c=' + campaignID
  }

  // Make the final request
  return fetch(url + params)
    .then(r => r.json())
}