import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import styles from './header.module.scss'
import logo from '../../media/redox_security_header_logo.svg'
import { navigate } from '@reach/router'
import { connect } from 'react-redux'
import { modalContactShow } from '../../state/dispatchers/modal-contact'

const Header = (props) => {
  // Change the header state based on scroll location relative to viewport height
  const changeHeaderWhenBelow = 0.5
  const [currentHeaderStyle, setCurrentHeaderStyle] = useState(`${styles.header} ${styles.headerPrimary}`)

  // Setup state for sidenav
  const [sideNavState, setSideNavState] = useState(false)

  // Toggle the state of the sidebar
  const toggleSidenav = () => {
    setSideNavState(!sideNavState)
  }

  // Shrink the header once it is past a specific height
  const onScroll = (e) => {
    const vh = Math.max(window.document.documentElement.clientHeight || 0, window.innerHeight || 0)
    if (window.pageYOffset > vh * changeHeaderWhenBelow) {
      setCurrentHeaderStyle(styles.headerSecondary)
    } else {
      setCurrentHeaderStyle(styles.headerPrimary)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })


  return (
    <header className={styles.header}>
      <div className={currentHeaderStyle}>

        <div className={styles.sidenav} style={{ display: sideNavState ? 'inline' : 'none' }}>
          <ul>
            <li>
              <button
                onClick={() => {
                  setSideNavState(!sideNavState)
                  navigate('/#approach')
                }}
                className={styles.navItem}>
                Approach</button>
            </li>
            <li>
              <button
                onClick={() => {
                  setSideNavState(!sideNavState)
                  navigate('/#services')
                }}
                className={styles.navItem}>
                Services</button>
          </li>
          <li>
            <button
              onClick={() => {
                setSideNavState(!sideNavState)
                navigate('/#company')
              }}
              className={styles.navItem}>
              Company</button>
          </li>
          <li>
            <Link
              to="/resources"
              className={styles.navItem}
              activeClassName={styles.navItemActive}>
              Resources</Link>
          </li>
          <li>
            <button
              onClick={() => {
                props.showContactModal('website_header_exercise_mobile')
              }}
              className={styles.ctaButton}
            >EXERCISE
            </button>
          </li>
        </ul>
      </div>

      <nav className={styles.nav}>
        <Link to="/">
          <img className={styles.logoImage} src={logo} alt="Redox Security"/>
        </Link>

        {/*<div className={styles.navList}>*/}
        {/*  <div className={styles.mainNav}>*/}
        {/*    <ul>*/}
        {/*      <li>*/}
        {/*        <Link*/}
        {/*          to="/#approach"*/}
        {/*          className={styles.navItem}*/}
        {/*          activeClassName={styles.navItemActive}>*/}
        {/*          Approach</Link>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <Link*/}
        {/*          to='/#services'*/}
        {/*          className={styles.navItem}*/}
        {/*          activeClassName={styles.navItemActive}>*/}
        {/*          Services</Link>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <Link*/}
        {/*          to="/#company"*/}
        {/*          className={styles.navItem}*/}
        {/*          activeClassName={styles.navItemActive}>*/}
        {/*          Company</Link>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <Link*/}
        {/*          to="/resources"*/}
        {/*          className={styles.navItem}*/}
        {/*          activeClassName={styles.navItemActive}>*/}
        {/*          Resources</Link>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <button*/}
        {/*          onClick={() => {*/}
        {/*            props.showContactModal('website_header_exercise')*/}
        {/*          }}*/}
        {/*          className={styles.ctaButton}*/}
        {/*        >EXERCISE*/}
        {/*        </button>*/}
        {/*      </li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*  <div className={styles.smallNav}>*/}
        {/*    <ul>*/}
        {/*      <li>*/}
        {/*        <button*/}
        {/*          className={styles.menuLines}*/}
        {/*          onClick={toggleSidenav}*/}
        {/*          aria-label="sidenav"*/}
        {/*        />*/}
        {/*      </li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </nav>

    </div>
</header>
)
}


const mapDispatchToProps = (dispatch) => ({
showContactModal: (classID) => dispatch(modalContactShow(classID)),
})

export default connect(null, mapDispatchToProps)(Header)
