import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import styles from './footer.module.scss'
import socialTwitterIcon from '../../media/social-twitter.svg'
import socialGithubIcon from '../../media/social-github.svg'
import socialLinkedInIcon from '../../media/social-linkedin.svg'
import socialRssIcon from '../../media/social-rss.svg'
import socialSubscribeIcon from '../../media/social-subscribe.svg'
import SignUpModal from '../signup-modal'

const Footer = () => {
  const data = useStaticQuery(graphql`
      {
          site {
              id
              siteMetadata {
                  author
              }
          }
      }
  `)


  const [subscribeModalOpen, setSubscribeModalOpen] = React.useState(false)

  const handleSubscribe = (e) => {
    setSubscribeModalOpen(true)
    return false
  }

  const handleSubscribeClose = () => {
    setSubscribeModalOpen(false)
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <p className={styles.copyright}>Copyright © 2020 {data.site.siteMetadata.author}.</p>
        <Link to="/privacy_policy" className={styles.policy}>Privacy Policy</Link>
        <div className={styles.social}>
          <a href="https://twitter.com/redoxsecurity"><img src={socialTwitterIcon} alt="Twitter"/></a>
          <a href="https://github.com/redoxsecurity"><img src={socialGithubIcon} alt="Github"/></a>
          <a href="https://linkedin.com/company/redox-security"><img src={socialLinkedInIcon} alt="LinkedIn"/></a>
          <a href="/rss.xml"><img src={socialRssIcon} alt="RSS"/></a>
          <button onClick={handleSubscribe}><img src={socialSubscribeIcon} alt="Subscribe"/></button>
        </div>
        <SignUpModal
          open={subscribeModalOpen}
          onClose={handleSubscribeClose}/>
      </div>
    </footer>

  )
}

export default Footer