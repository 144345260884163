import React from 'react'
import styles from './hero.module.scss'
import defaultImage from './color_keyboard.jpg'
import PropTypes from 'prop-types'

class Hero extends React.Component {
  static defaultProps = {
    heroHeight: '66vh',
    heroImage: defaultImage
  }

  constructor(props) {
    super(props)
    this.onScroll = this.onScroll.bind(this)
    this.backgroundID = `${styles.hero}--background`
  }

  onScroll(e) {
    const heroElement = window.document.getElementById(this.backgroundID)
    if (heroElement) {
      let scroll = (window.pageYOffset - heroElement.offsetTop) / 3
      let y = scroll > 0 ? +scroll : scroll
      heroElement.style.backgroundPosition = '0% ' + y + 'px'
    }
  }

  componentDidMount() {
    // Parallax effect JavaScript
    window.document.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.document.removeEventListener('scroll', this.onScroll)
  }

  render() {
    //  Create dynamic CSS based on provided image URL
    const heroBackgroundStyle = {
      backgroundImage: `url("${this.props.heroImage}")`,
      height: this.props.heroHeight
    }

    return (
      <div id={this.backgroundID} style={heroBackgroundStyle} className={styles.wrapper}>
        <div className={styles.hero} style={{ height: this.props.heroHeight }}>
          <div className={styles.heroTopSpacer}/>

          {this.props.tagline &&
          <div className={styles.heroTagline}>
            {this.props.tagline}
          </div>
          }

          {this.props.ctaText && <button className={styles.heroButton} onClick={this.props.handleClick}>
            {this.props.ctaText}
          </button>}
          <div className={styles.heroBottomSpacer}/>
        </div>
      </div>
    )
  }
}


Hero.propTypes = {
  heroHeight: PropTypes.string,
  heroImage: PropTypes.string,
  tagline: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleClick: PropTypes.func
}

export default Hero