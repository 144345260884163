export const modalContactShow = (sourceID = '') => ({
  type: 'MODAL_CONTACT_SHOW',
  sourceID: sourceID
})

export const modalContactHide = () => ({
  type: 'MODAL_CONTACT_HIDE'
})

export const modalContactShowOK = () => ({
  type: 'MODAL_CONTACT_SHOW_OK'
})

export const modalContactHideOK = () => ({
  type: 'MODAL_CONTACT_HIDE_OK'
})

export const modalContactShowError = () => ({
  type: 'MODAL_CONTACT_SHOW_ERROR'
})

export const modalContactHideError = () => ({
  type: 'MODAL_CONTACT_HIDE_ERROR'
})
